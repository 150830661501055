import { createContext } from 'react'
import { SelectionModifier, WorkspaceSelection } from '../state'

export type SelectionContextType = [
  selection: WorkspaceSelection[] | undefined,
  setSelection: (selection: WorkspaceSelection, modifier?: SelectionModifier) => void,
]
export const SelectionContext = createContext<SelectionContextType>([undefined, () => {}])

export const isSelectedToken = (selection: WorkspaceSelection[] | undefined, token_id: string) =>
  !!selection?.some((item) => item.token_id === token_id)
