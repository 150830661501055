import { Token } from 'api/template'
import { IconFieldSignature } from 'icons'
import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import styles from './page-item.module.scss'

interface Props extends Omit<ComponentProps<'div'>, 'children' | 'onSelect'> {
  token: Token.Signature
}
export const PageTokenSignature: FC<Props> = ({ className, token, ...props }) => {
  return (
    <div
      {...props}
      className={cn(styles.item, styles.signature, className)}
      data-role={token.role.toLowerCase()}
      data-fixed={token._fixed ? 'true' : undefined}
    >
      <IconFieldSignature className={styles.icon} />
      <span className={styles.label}>{Token.getGenericLabel(token)}</span>
    </div>
  )
}
