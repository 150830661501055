import useResizeObserver from '@react-hook/resize-observer'
import { UserSignature, userSignature as userSignatureApi } from 'api/user-signature'
import { useRef, useCallback, ComponentProps, useEffect, useState, FC } from 'react'
import { Form, TextField, SubmitButton, Select, useContainerZoom } from 'ui'
import { alertErrorMessage, alertSuccessMessage } from 'utils/toast'
import styles from './type.module.scss'

interface Props {
  type: UserSignature.Type
  defaultValue?: string | null
  message?: string
  label?: string
  submitLabel?: string
  onSuccess?: (data: { snapshot_id: string; blob: Blob }) => void
}

export const SignatureType: FC<Props> = ({
  type,
  defaultValue,
  message = UserSignature.MSG.ACTION.UPLOAD_SUCCESS,
  label = type === UserSignature.Type.INITIALS ? 'Initials' : 'Full Legal Name',
  submitLabel = UserSignature.MSG.ACTION.UPLOAD,
  onSuccess,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const configs = useRef([...CONFIGS])
  const [fontConfig, setFontConfig] = useState<FontConfig>(configs.current[0])
  const [text, setText] = useState(defaultValue ?? undefined)

  const handleChange: ComponentProps<typeof Form>['onChange'] = useCallback(
    (data: Partial<Data>) => {
      if (data.value) {
        setFontConfig(configs.current.find((cfg) => cfg.value === data.value) ?? configs.current[0])
      }
      setText(data.name ?? '')
    },
    [configs],
  )

  const [containerRef, zoom] = useContainerZoom({
    pageWidth: UserSignature.SIZE.WIDTH,
    pageHeight: UserSignature.SIZE.HEIGHT,
  })

  const draw = useCallback(
    (
      text: string,
      font: FontConfig['value'],
      size: { width: number; height: number } = {
        width: UserSignature.SIZE.WIDTH,
        height: UserSignature.SIZE.HEIGHT,
      },
    ) => {
      const canvas = canvasRef.current
      if (!canvas) throw new Error('Canvas not found')
      const ctx = canvas.getContext('2d')
      if (!ctx) throw new Error('Canvas 2D context not found')

      ctx.reset()
      ctx.scale(zoom, zoom)

      ctx.font = font
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillStyle = 'black'
      const { width } = ctx.measureText(text)
      const maxWidth = size.width * zoom
      const scale = width < maxWidth ? 1 : maxWidth / width
      if (scale !== 1) {
        ctx.scale(scale, scale)
      }
      const scaledWidth = size.width / scale
      const scaledHeight = size.height / scale

      ctx.fillText(text, scaledWidth / 2, scaledHeight / 2)
      return { scale, width, scaledWidth, scaledHeight }
    },
    [zoom],
  )

  useResizeObserver(containerRef, () => text && fontConfig && draw(text, fontConfig.value))

  useEffect(() => {
    if (fontConfig.loaded && text) {
      draw(text, fontConfig.value)
    } else {
      const font = new FontFace(fontConfig.label, `url(${fontConfig.url}) format('woff2')`)
      document.fonts.add(font)
      document.fonts
        .load(fontConfig.value)
        .then(() => {
          fontConfig.loaded = true
        })
        .then(() => text && draw(text, fontConfig.value))
    }
  }, [draw, fontConfig, text])

  const upload = useCallback(async () => {
    try {
      const canvas = canvasRef.current
      if (!canvas) return
      if (!text) return

      const { scaledWidth } = draw(text, fontConfig.value)
      const padding = 10
      const width = scaledWidth + padding * 2
      const height = fontConfig.size + padding * 2
      canvas.width = width
      canvas.height = height
      draw(text, fontConfig.value, { width, height })

      const file = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob) => (blob ? resolve(blob) : reject()), 'image/png')
      })
      const { snapshot_id } = await userSignatureApi.upload({ file, type })
      alertSuccessMessage(message)
      onSuccess?.({ snapshot_id, blob: file })
    } catch (error) {
      alertErrorMessage(error as Error)
    }
  }, [draw, fontConfig.size, fontConfig.value, message, onSuccess, text, type])

  return (
    <div ref={containerRef}>
      <Form className={styles.form} onSubmit={upload} onChange={handleChange}>
        <fieldset className={styles.fieldset}>
          <TextField
            name="name"
            label={label}
            defaultValue={defaultValue}
            className={styles.name}
          />
          <Select
            name="value"
            label="Font Family"
            options={CONFIGS}
            defaultValue={configs.current[0].value}
            className={styles.value}
          />
        </fieldset>

        <canvas
          className={styles.canvas}
          ref={canvasRef}
          height={UserSignature.SIZE.HEIGHT * zoom}
          width={UserSignature.SIZE.WIDTH * zoom}
        />
        <footer>
          <SubmitButton>{submitLabel}</SubmitButton>
        </footer>
      </Form>
    </div>
  )
}
interface Data {
  name: string
  value: string
}

type FontConfig = { label: string; value: string; url: string; loaded: boolean; size: number }

const CONFIGS: FontConfig[] = [
  {
    label: 'Allison',
    size: 96,
    url: 'https://fonts.gstatic.com/s/allison/v11/X7nl4b88AP2nkbvZCCGa4ebjEgg.woff2',
  },
  {
    label: 'Dancing Script',
    size: 96,
    url: 'https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8hNX6plRP.woff2',
  },
  {
    label: 'Sacramento',
    size: 96,
    url: 'https://fonts.gstatic.com/s/sacramento/v15/buEzpo6gcdjy0EiZMBUG4C0f_f5Iai0.woff2',
  },
  {
    label: 'Hurricane',
    size: 96,
    url: 'https://fonts.gstatic.com/s/hurricane/v7/pe0sMIuULZxTolZ5YldCBfe_Kdxicw.woff2',
  },
].map((cfg) => ({
  ...cfg,
  value: `${cfg.size}px "${cfg.label}"`,
  loaded: false,
}))
