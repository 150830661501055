import { FC, ComponentProps, useRef, useState, useEffect } from 'react'
import { DragPreviewImage, XYCoord, useDrag, useDrop } from 'react-dnd'
import { cn } from 'utils'
import styles from './page-dropzone.module.scss'
import { useEditorApi, SelectionRect, DraggableType, DroppableItem } from '../state'

interface Props extends ComponentProps<'div'> {
  zoom: number
  pageId: number
  onSelectionEnd?: () => void
}

export const PageDropzone: FC<Props> = ({
  children,
  className,
  zoom,
  pageId,
  onSelectionEnd,
  ...props
}) => {
  const api = useEditorApi()
  const [selection, setSelection] = useState<SelectionRect | null>(null)
  const [{ isSelecting }, dragRef, previewRef] = useDrag<
    SelectionStart,
    undefined,
    { isSelecting: boolean }
  >(
    () => ({
      type: DraggableType.WORKSPACE,
      collect: (monitor) => ({ isSelecting: monitor.isDragging() }),
      item: (monitor) => {
        const bounds = ref.current?.getBoundingClientRect()
        if (!bounds) return null
        const offset = monitor.getInitialClientOffset()
        if (!offset) return null
        return {
          x: offset.x - bounds.left,
          y: offset.y - bounds.top,
          selection: true,
        }
      },
      end: () => {
        setSelection(null)
        onSelectionEnd?.()
      },
    }),
    [zoom],
  )
  const ref = useRef<HTMLDivElement>(null)
  const [{ canDrop, hovered }, dropRef] = useDrop<
    DroppableItem | SelectionStart,
    WorkspacePageDropResult,
    CollectedProps
  >(
    () => ({
      accept: [DraggableType.TOOLBOX, DraggableType.WORKSPACE],
      collect(monitor) {
        return {
          hovered: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }
      },
      hover(start, monitor) {
        if (!isSelection(start)) return
        const bounds = ref.current?.getBoundingClientRect()
        if (!bounds) return
        const offset = monitor.getClientOffset()
        if (!offset) return
        const end = { x: offset.x - bounds.left, y: offset.y - bounds.top }
        const min = {
          x: Math.min(start.x, end.x),
          y: Math.min(start.y, end.y),
        }
        const max = {
          x: Math.max(start.x, end.x),
          y: Math.max(start.y, end.y),
        }
        const selection = {
          left: min.x,
          top: min.y,
          height: max.y - min.y,
          width: max.x - min.x,
        }
        setSelection(selection)
      },
      drop(item, monitor) {
        if (isSelection(item)) return

        const element = ref.current
        const pos = monitor.getClientOffset()
        if (!pos || !element) return
        const { x, y } = getOffsetRelativeToElement({
          clientOffset: monitor.getClientOffset(),
          element,
          item,
          zoom,
        })
        return { x, y, page_number: pageId, zoom }
      },
    }),
    [pageId, zoom],
  )

  useEffect(() => {
    selection &&
      api?.selectRect(pageId, {
        left: selection.left / zoom,
        top: selection.top / zoom,
        width: selection.width / zoom,
        height: selection.height / zoom,
      })
  }, [api, pageId, selection, zoom])

  return (
    <>
      <DragPreviewImage
        connect={previewRef}
        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      />
      <div
        {...props}
        className={cn(
          styles.wrapper,
          hovered && styles.hover,
          !canDrop && hovered && styles.forbidden,
          isSelecting && styles.selecting,
          className,
        )}
        ref={(el) => dropRef(dragRef(el))}
        onClick={api?.clearSelection}
      >
        <div ref={ref} />
        {selection && <div className={styles.selection} style={selection} />}
      </div>
    </>
  )
}

function getOffsetRelativeToElement({
  clientOffset,
  element,
  item,
  zoom,
}: {
  clientOffset: XYCoord | null
  element: HTMLDivElement
  item: DroppableItem
  zoom: number
}) {
  if (!clientOffset) throw new Error('clientOffset is not defined')
  if (!element) throw new Error('element is not defined')
  const bounds = element.getBoundingClientRect()
  return {
    x: Math.round((clientOffset.x - bounds.left - (item.mouseLeft ?? 0)) / zoom),
    y: Math.round((clientOffset.y - bounds.top - (item.mouseTop ?? 0)) / zoom),
  }
}

interface CollectedProps {
  hovered: boolean
  canDrop: boolean
}

export interface WorkspacePageDropResult {
  x: number
  y: number
  page_number: number
  zoom: number
}

interface SelectionStart {
  x: number
  y: number
  selection: true
}
function isSelection(item: SelectionStart | DroppableItem): item is SelectionStart {
  return !!(item as SelectionStart)?.selection
}
