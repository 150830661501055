import { Radio, Token } from 'api/template'
import { EditorState, WorkspaceSelection } from './types'

export function getRawTokens(state: EditorState): Token.Raw[] {
  return state.tokens.map(({ _index, _fixed, ...token }) => {
    if (Token.isRadioToken(token as Token)) {
      return {
        ...token,
        radio: (token as Token.TokenRadio).radio.map(
          ({ _fixed, _index, _radioIndex, ...radio }) => radio,
        ),
      } as Token.Raw
    }
    return token as Token.Raw
  })
}

export const isSelected = (
  selection: WorkspaceSelection[] | undefined,
  token_id: string,
  radio_id?: string,
) => !!selection?.some((item) => item.token_id === token_id && item.radio_id === radio_id)

type Item = { token: Token; radio?: never } | { token: Token.TokenRadio; radio: Radio }

type ItemFilter = (item: Token | Radio) => boolean

export function getFilteredItems(state: EditorState, filter?: ItemFilter): Item[] {
  const results: Item[] = []
  state.tokens.forEach((token) => {
    if (Token.isRadioToken(token)) {
      token.radio.forEach((radio) => {
        if (filter ? filter(radio) : true) results.push({ token, radio })
      })
    } else {
      if (filter ? filter(token) : true) results.push({ token })
    }
  })
  return results
}

export function hasFilteredItems(state: EditorState, filter: ItemFilter): boolean {
  return state.tokens.some((token) => {
    if (Token.isRadioToken(token)) {
      return token.radio.some(filter)
    } else {
      return filter(token)
    }
  })
}

export function getPageItems(state: EditorState, page_number: number): Item[] {
  return getFilteredItems(state, Token.byPageNumber(page_number))
}

export function getFixedItems(state: EditorState): Item[] {
  return getFilteredItems(state, (item) => !!item._fixed)
}
export function hasFixedItems(state: EditorState): boolean {
  return hasFilteredItems(state, (item) => !!item._fixed)
}

export const itemToSelection = (item: Item): WorkspaceSelection => {
  return {
    token_id: item.token.token_id,
    radio_id: item.radio?.radio_id,
  }
}
