import { Token } from 'api/template'
import { FC } from 'react'
import { MoneyField } from 'ui'
import { cn } from 'utils'
import styles from './page-field.module.scss'

interface Props {
  token: Token
  props?: Token.ConditionalProps
}

export const PageFieldNumber: FC<Props> = ({ token, props }) => {
  if (props?.hidden) return null
  if (!Token.isNumber(token)) return null

  const value = Number(props?.value)
  const fancy = Token.isReadOnly(token)
  const format = fancy ? Token.createNumberFormatter(token.format) : undefined

  return (
    <>
      <MoneyField
        autoComplete="off"
        className={cn(styles.pagefield, styles.field, fancy && styles.hidden)}
        defaultValue={value}
        id={token.token_id}
        inputClassName={styles.input}
        label={token.label}
        labelClassName={styles.label}
        name={token.token_id}
        readOnly={token.read_only}
        required={token.read_only ? false : token.required}
        validationClassName={styles.error}
        placeholder=""
      />
      {fancy && <span className={cn(styles.pagefield, styles.static)}>{format?.(value)}</span>}
    </>
  )
}
