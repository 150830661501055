import { PdfPageDefinition } from '@rello/pdf'
import { Template, TemplateCustomToken, Radio, Token, ApiToken } from 'api/template'
import { WorkspacePageDropResult } from '../page'

export interface EditorState {
  tokens: Token[]
  selection: WorkspaceSelection[] | undefined
  fields: TemplateCustomToken.Group[]
  history: { tokens: Token[] }[]
  historyIndex: number
  pages: PdfPageDefinition[]
  template: Template
  /** map `apiToken.name` - `apiToken` */
  apiTokenMap: Record<string, ApiToken>
}

export const enum EditorActionType {
  ADD_RADIO = 'add-radio',
  ALIGN_SELECTION = 'align-selection',
  CLEAR_SELECTION = 'clear-selection',
  CREATE = 'create',
  DELETE_RADIO = 'delete-radio',
  DELETE_SELECTED = 'delete-selected',
  DELETE_TOKEN = 'delete-token',
  REDO = 'redo',
  SELECT = 'select',
  SELECT_RECTANGLE = 'select-rect',
  SET_PAGES = 'set-pages',
  UNDO = 'undo',
  UPDATE_RADIO = 'update-radio',
  UPDATE_SELECTED = 'update-selected',
  UPDATE_TOKEN = 'update-token',
}

export type EditorAction =
  | { type: EditorActionType.SET_PAGES; pages: PdfPageDefinition[] }
  | {
      type: EditorActionType.SELECT
      selection: WorkspaceSelection | WorkspaceSelection[]
      modifier?: SelectionModifier
    }
  | {
      type: EditorActionType.SELECT_RECTANGLE
      rect: SelectionRect
      page: number
      modifier?: SelectionModifier
    }
  | { type: EditorActionType.CLEAR_SELECTION }
  | { type: EditorActionType.ALIGN_SELECTION; alignment: Alignment }
  | {
      type: EditorActionType.UPDATE_TOKEN
      update: Update<Token>
      token_id: string
    }
  | {
      type: EditorActionType.UPDATE_RADIO
      update: Update<Radio>
      token_id: string
      radio_id: string
    }
  | {
      type: EditorActionType.UPDATE_SELECTED
      update: Update<Radio> | Update<Token>
    }
  | { type: EditorActionType.DELETE_SELECTED }
  | { type: EditorActionType.DELETE_TOKEN; token_id: string }
  | { type: EditorActionType.DELETE_RADIO; token_id: string; radio_id: string }
  | { type: EditorActionType.ADD_RADIO; token_id: string }
  | {
      type: EditorActionType.CREATE
      result: WorkspacePageDropResult
      field: TemplateCustomToken.Field
    }
  | { type: EditorActionType.UNDO }
  | { type: EditorActionType.REDO }

export type EditorActionOf<T extends EditorActionType> = Extract<EditorAction, { type: T }>

export type SelectionRect = { left: number; top: number; width: number; height: number }

export const enum Alignment {
  H_LEFT = 'left',
  H_CENTER = 'horizontal',
  H_RIGHT = 'right',
  V_TOP = 'top',
  V_CENTER = 'vertical',
  V_BOTTOM = 'bottom',
}

export type WorkspaceSelection = {
  token_id: string
  radio_id?: string
}

export const enum SelectionModifier {
  ADD = 'add',
  REMOVE = 'remove',
}

export const enum DraggableType {
  TOOLBOX = 'toolbox',
  WORKSPACE = 'workspace',
}

export interface DroppableItem {
  mouseTop: number
  mouseLeft: number
}

export interface FieldCreate {
  result: WorkspacePageDropResult
  field: TemplateCustomToken.Field
}

export type Update<T> = Partial<T> | ((item: T) => Partial<T>)

export type UpdateHandler<T> = (update: Update<T>) => any
