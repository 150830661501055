import { Radio, Token } from 'api/template'
import { FC, ComponentProps } from 'react'
import { PageToken } from './page-token'
import { useSelectableProps } from '../selection'

type Props = Omit<ComponentProps<'div'>, 'children'> & {
  zoom: number
  selected?: boolean
} & ({ token: Token; radio?: never } | { token: Token.TokenRadio; radio: Radio })

export const SelectablePageToken: FC<Props> = ({
  className,
  token,
  radio,
  zoom,
  selected,
  ...props
}) => {
  const selectableProps = useSelectableProps({
    token_id: token.token_id,
    radio_id: radio?.radio_id,
  })

  return Token.isRadioToken(token) && radio ? (
    <PageToken {...props} token={token} radio={radio} zoom={zoom} {...selectableProps} />
  ) : (
    <PageToken {...props} token={token} zoom={zoom} {...selectableProps} />
  )
}
