import { PdfViewer } from '@rello/pdf'
import { Token } from 'api/template'
import { UserSignature } from 'api/user-signature'
import { convertToServerData } from 'client'
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'ui'
import { cn } from 'utils'
import { not } from 'utils/compose'
import { alertErrorMessage } from 'utils/toast'
import { LeaseBuilderActions } from './lease-builder-actions'
import styles from './lease-builder.module.scss'
import { PageField } from './page-field'
import { SignatureContextProvider } from './signature-context'
import editorStyles from '../editor/admin-template-editor.module.scss'

type Data = Record<string, string>

interface Props<T> {
  pdf: Blob
  footerClassName?: string
  className?: string
  defaultValues?: Partial<Data>
  onSubmit: (values: Data) => Promise<T>
  redirect?: (result: T) => any
  fields: Token[]
  submitLabel: string
  signatures?: Record<UserSignature.PersistentType, { blob: Blob; snapshot_id: string }>
  loaderElement?: ReactNode
  vars?: Record<string, any>
}

export function LeaseBuilder<T>({
  pdf,
  className,
  footerClassName,
  defaultValues,
  submitLabel,
  redirect,
  onSubmit,
  fields,
  signatures,
  loaderElement,
  vars = {},
}: Props<T>) {
  const [activeTokenId, setActiveTokenId] = useState<string>()
  const [submitting, setSubmitting] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const dateFieldIds = fields.filter(Token.isDate).map(Token.pickId)
  const enabledFields = fields.filter(not(Token.isReadOnly))

  const formValuesTransformer = useMemo(() => {
    const namedEntries = fields.reduce(
      (mapIdName, field) =>
        field.name ? [...mapIdName, [field.name, field.token_id] as [string, string]] : mapIdName,
      [] as [string, string][],
    )
    return (values: Record<string, any>) => {
      return namedEntries.reduce((result, [name, id]) => {
        if (id in values) result[name] = values[id]
        return result
      }, (vars as Record<string, any>) ?? {})
    }
  }, [fields, vars])

  const isLast = !!activeTokenId && enabledFields.at(-1)?.token_id === activeTokenId
  const isFirst = !activeTokenId || enabledFields[0]?.token_id === activeTokenId

  useEffect(() => {
    const element = contentRef.current
    if (!element) return
    const listener = (event: FocusEvent) => {
      const name = getTokenId(event.target as HTMLElement)
      if (name) setActiveTokenId(name)
    }
    element.addEventListener('focusin', listener)
    return () => element.removeEventListener('focusin', listener)
  }, [])

  const next = useCallback(
    (inc = 1) => {
      let index = activeTokenId ? enabledFields.findIndex(Token.byId(activeTokenId)) : -1
      let next: HTMLElement | undefined
      do {
        index += inc
        if (index < 0 || index >= enabledFields.length) return
        let token_id = enabledFields[index]?.token_id
        // console.log('current token_id', token_id)
        if (!token_id) return
        next = findElementByTokenId(token_id, contentRef.current)
        // console.log('next element', next)
      } while (!next)
      next.scrollIntoView({ behavior: 'smooth', block: 'center' })
      next.focus({ preventScroll: true })
    },
    [activeTokenId, enabledFields],
  )

  const submit = useCallback(
    async (values: Data) => {
      setSubmitting(true)
      return await onSubmit(convertToServerData(values, { date: dateFieldIds })).catch((e) => {
        setSubmitting(false)
        alertErrorMessage(e)
        throw e
      })
    },
    [dateFieldIds, onSubmit],
  )

  return (
    <>
      <SignatureContextProvider value={signatures}>
        <Form
          defaultValues={defaultValues}
          onSubmit={submit}
          className={cn(styles.form, submitting && styles.submitting, className)}
          redirect={redirect}
        >
          <div className={cn(editorStyles.section, styles.workspace)} ref={contentRef}>
            <PdfViewer
              pdf={pdf}
              className={styles.document}
              onError={alertErrorMessage}
              renderPageContent={({ zoom, id }) => {
                if (!zoom) return <></>
                return (
                  <>
                    {fields.filter(Token.byPageNumber(id)).map((token) => (
                      <PageField
                        token={token}
                        zoom={zoom}
                        key={token.token_id}
                        scope={vars}
                        formValuesTransformer={formValuesTransformer}
                      />
                    ))}
                  </>
                )
              }}
            />
          </div>
          <footer className={cn(styles.footer, footerClassName)}>
            <LeaseBuilderActions
              onNext={next}
              submitLabel={submitLabel}
              isFirst={isFirst}
              isLast={isLast}
              count={enabledFields.length}
            />
          </footer>
        </Form>
      </SignatureContextProvider>
      {submitting && loaderElement}
    </>
  )
}

const getTokenId = (element: HTMLElement) =>
  element && ['INPUT', 'SELECT', 'BUTTON'].includes(element.tagName) && element.hasAttribute('name')
    ? element.getAttribute('name')
    : undefined

const findElementByTokenId = (next?: string, container?: HTMLElement | null) => {
  if (!next || !container) return undefined
  return container.querySelector(`[name="${next}"]:is(input,select,button)`) as
    | HTMLSelectElement
    | HTMLInputElement
    | HTMLButtonElement
    | undefined
}
