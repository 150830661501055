import { Token } from 'api/template'
import { FC } from 'react'
import { DateField } from 'ui'
import { cn } from 'utils'
import { serverToISODate } from 'utils/date'
import styles from './page-field.module.scss'

interface Props {
  token: Token
  props?: Token.ConditionalProps
  leaseSignDate?: string
}

export const PageFieldDate: FC<Props> = ({ token, props, leaseSignDate }) => {
  if (props?.hidden || !Token.isDate(token)) return null
  const originalValue = token.lock_to_sign_date ? leaseSignDate : props?.value
  const value = typeof originalValue === 'string' ? serverToISODate(originalValue) : undefined
  const fancy = Token.isReadOnly(token) && !!value
  const format = fancy ? Token.createDateFormatter(token.format) : undefined
  return (
    <>
      <DateField
        id={token.token_id}
        name={token.token_id}
        label={token.label}
        labelClassName={styles.label}
        required={token.required}
        className={cn(styles.pagefield, styles.field, fancy && styles.hidden)}
        readOnly={Token.isReadOnly(token)}
        defaultValue={value}
        validationClassName={styles.error}
        inputClassName={styles.input}
      />
      {format && value && (
        <span
          className={cn(styles.pagefield, styles.static)}
          data-original-value={originalValue}
          data-value={value}
        >
          {format(new Date(value))}
        </span>
      )}
    </>
  )
}
