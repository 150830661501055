import { Token } from 'api/template'
import { FC, ComponentProps, useCallback, KeyboardEventHandler, useRef, useEffect } from 'react'
import { cn } from 'utils'
import styles from './keyboard-control.module.scss'
import { useEditorApi, useEditorState } from '../state'

interface Props extends Omit<ComponentProps<'section'>, 'onKeyDown'> {}

export const KeyboardControl: FC<Props> = ({ children, className, tabIndex = 0, ...props }) => {
  const api = useEditorApi()
  const state = useEditorState()

  const ref = useRef<HTMLDivElement>(null)
  const keyboardHandler = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      if (event.isPropagationStopped()) return
      switch (event.key) {
        case 'ArrowLeft':
          api?.updateSelected((token: Token) => ({ x: token.x - 1 }))
          break
        case 'ArrowRight':
          api?.updateSelected((token: Token) => ({ x: token.x + 1 }))
          break
        case 'ArrowUp':
          api?.updateSelected((token: Token) => ({ y: token.y - 1 }))
          break
        case 'ArrowDown':
          api?.updateSelected((token: Token) => ({ y: token.y + 1 }))
          break
        case 'Delete':
        case 'Backspace':
          api?.deleteSelected()
          break
        case 'Escape':
          api?.clearSelection()
          break
        case 'z':
          if (event.ctrlKey || event.metaKey) {
            event.shiftKey ? api?.redo() : api?.undo()
            event.preventDefault()
          }
          break

        default:
          return
      }
      event.preventDefault()
      event.stopPropagation()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (!ref.current) return

    // Making sure that focus is within the container
    if (document.activeElement && ref.current.contains(document.activeElement)) return

    // Making sure that focus is within the container
    const selectedElement = ref.current.querySelector('[data-selected="true"]') as HTMLDivElement
    if (selectedElement) {
      selectedElement.focus?.()
    } else {
      ref.current?.focus({ preventScroll: true })
    }
  }, [state])

  return (
    <section
      {...props}
      className={cn(className, styles.section)}
      onKeyDown={keyboardHandler}
      tabIndex={tabIndex}
      ref={ref}
    >
      {children}
    </section>
  )
}
