import { Token } from 'api/template'
import { FC, useCallback } from 'react'
import { Button } from 'ui'
import { ToolboxRadioProperties } from './toolbox-radio-properties'
import styles from './toolbox-token-properties.module.scss'
import { useEditorApi } from '../state'

interface Props {
  token: Token.TokenRadio
}
export const ToolboxTokenRadios: FC<Props> = ({ token }) => {
  const api = useEditorApi()
  const addRadio = useCallback(() => {
    Token.isRadioToken(token) && api?.addRadioByIndex?.(token.token_id)
  }, [api, token])

  return (
    <>
      {token.radio.map((radio, index) => (
        <ToolboxRadioProperties key={index} radio={radio} token={token} className={styles.field} />
      ))}
      <footer>
        <Button theme="secondary" onClick={addRadio} className={styles.tiny}>
          Add
        </Button>
      </footer>
    </>
  )
}
