import { useContext } from 'react'
import { SelectionContext } from './selection'
import { SelectionModifier, WorkspaceSelection } from '../state'
import { isSelected } from '../state/selectors'

export const useSelected = (
  item: WorkspaceSelection,
): [selected: boolean, select: (modifier?: SelectionModifier) => void] => {
  const [selection, setSeletion] = useContext(SelectionContext)
  return [
    isSelected(selection, item?.token_id, item?.radio_id),
    (modifier?: SelectionModifier) => setSeletion(item, modifier),
  ]
}
