import { Parser } from 'expr-eval'
import { ComponentPropsWithoutRef } from 'react'
import { FormValue } from 'ui'
import { BaseField, BaseFieldProps, withFormDefaultValues } from 'ui/form/field/_base'
import { TextArea } from 'ui/form/input-text/text-area'
import { cn } from 'utils'
import styles from './formula-field.module.scss'

type TextAreaProps = Exclude<ComponentPropsWithoutRef<typeof TextArea>, BaseFieldProps<string>>

type Props = TextAreaProps &
  BaseFieldProps<string> & {
    scope: Set<string>
  }

class Field extends BaseField<string, Props> {
  static displayName = 'FormulaField'

  get className() {
    return cn(super.className, styles.field)
  }
  get validationClassName() {
    return cn(super.validationClassName, styles.error)
  }
  get labelClassName() {
    return cn(super.labelClassName, styles.label)
  }

  get inputClassName() {
    return cn(super.inputClassName, styles.formulainput)
  }

  validate = (value?: string | null) => {
    if (!value) return null
    if (!this.props.scope) return 'Scope is required'
    try {
      const expression = this.parse()
      if (!expression) return 'Invalid expression'
      const variables = expression.variables()
      for (const v of variables) {
        if (!this.props.scope.has(v)) return `Unknown token: ${v}`
      }
    } catch (e) {
      return (e as Error)?.message
    }
    return null
  }

  parse(value = this.getValue()) {
    if (!value?.trim()) return null
    return Parser.parse(value)
  }

  renderInput({ className, ...props }: TextAreaProps) {
    return (
      <div className={styles.wrapper}>
        <TextArea rows={1} {...props} validation={this.validate} className={this.inputClassName} />
        <FormValue>
          {({ values }) => <span className={styles.ghost}>{values[this.props.name]}</span>}
        </FormValue>
      </div>
    )
  }
}

export const FormulaField = withFormDefaultValues<string, Props>(Field)
