import { Token } from 'api/template'
import { CSSProperties, FC, useState } from 'react'
import { CheckboxField, RadioField, TextField, useFormValues } from 'ui'
import { cn } from 'utils'
import { PageFieldDate } from './page-field-date'
import { PageFieldNumber } from './page-field-number'
import styles from './page-field.module.scss'
import { SignatureField } from './signature-field'
import { getPageItemStyle } from '../util'

interface Props {
  token: Token
  zoom: number
  scope: Record<string, string>
  formValuesTransformer: (values: Record<string, any>) => Record<string, any>
}
export const PageField: FC<Props> = ({ token, zoom, scope: _scope, formValuesTransformer }) => {
  const [conditions] = useState(Token.getConditions(token))
  const values = formValuesTransformer(useFormValues())
  const scope = {
    ..._scope,
    ...values,
  }
  const props = Token.evaluateConditions(conditions, scope)
  if (!props) {
    // error in formulas
    return null
  }
  if (props.hidden) return null

  return Token.isRadioToken(token) ? (
    <RadioField
      name={token.token_id}
      label={token.label}
      required={token.required}
      className={styles.radio}
      inputClassName={styles.options}
      labelClassName={styles.label}
      validationClassName={styles.error}
    >
      {token.radio.map((radio) => (
        <label
          key={radio.radio_id}
          style={getPageItemStyle(radio, zoom)}
          className={cn(styles.pagefield, styles.option)}
          data-role={token.role.toLowerCase()}
          aria-required={token.required}
        >
          <input
            type="radio"
            name={token.token_id}
            value={radio.value}
            defaultChecked={radio.checked === '1'}
            className={styles.radioinput}
            id={radio.radio_id}
          />
          <span>{radio.value}</span>
        </label>
      ))}
    </RadioField>
  ) : (
    <div
      className={styles.wrapper}
      data-role={token.role.toLowerCase()}
      style={getPageItemStyle(token, zoom)}
    >
      {getSingleElement(token, props, scope)}
    </div>
  )
}

function getSingleElement(
  token: Token,
  props?: Token.ConditionalProps,
  scope?: Record<string, any>,
) {
  switch (token.type) {
    case Token.Type.TEXT:
      return (
        <TextField
          autoComplete="off"
          className={cn(styles.pagefield, styles.field)}
          defaultValue={
            typeof props?.value === 'string' ? props.value : (token as Token.Text).prefilled_text
          }
          id={token.token_id}
          inputClassName={styles.input}
          label={token.label}
          labelClassName={styles.label}
          name={token.token_id}
          readOnly={token.read_only}
          required={token.required}
          validationClassName={styles.error}
        />
      )
    case Token.Type.NUMBER:
      return <PageFieldNumber token={token} props={props} />
    case Token.Type.DATE:
      return <PageFieldDate token={token} props={props} leaseSignDate={scope?.lease_start} />

    case Token.Type.CHECKBOX:
      return (
        <CheckboxField
          id={token.token_id}
          name={token.token_id}
          label={token.label}
          labelClassName={styles.label}
          required={token.required}
          className={cn(styles.pagefield, styles.checkbox)}
          validationClassName={styles.error}
          style={{ '--size': token.size } as CSSProperties}
        />
      )
    case Token.Type.SIGNATURE:
      return (
        <SignatureField
          token={token as Token.Signature}
          className={cn(styles.pagefield, styles.signature)}
        />
      )
  }
}
