import { ApiToken, TemplateCustomToken, Token } from 'api/template'
import { UserSignature } from 'api/user-signature'
import { FC, ComponentProps, useCallback, useMemo } from 'react'
import {
  Card,
  CheckboxField,
  Form,
  FormValue,
  HiddenField,
  NumberField,
  Select,
  TextAreaField,
  TextField,
  Tooltip,
} from 'ui'
import { cn } from 'utils'
import { CurrentScope } from './current-scope'
import { DateData, dateDataToFormat, FormatDate, hasDateData, tokenToDateData } from './format-date'
import {
  FormatNumber,
  hasNumberData,
  NumberData,
  numberDataToFormat,
  tokenToNumberData,
} from './format-number'
import { FormulaField } from './formula-field'
import { ItemFixes } from './item-fixes'
import styles from './toolbox-token-properties.module.scss'
import { ToolboxTokenRadios } from './toolbox-token-radios'
import { RoleIcon } from '../icons/role-icon'
import { useEditorApi, useEditorState } from '../state'

interface Props extends Omit<ComponentProps<typeof Card>, 'children'> {
  token: Token
}

type Data = {
  token_id: string
  required: boolean
  read_only: boolean
  name?: string
  label?: string
  description?: string
  subtype?: UserSignature.Type
  role: Token.Role
  prefilled_text?: string
  lock_to_sign_date?: boolean
  date_format?: string
  x: number
  y: number
  width: number
  height: number
  _hidden?: string
  _value?: string
} & DateData &
  NumberData

export const ToolboxTokenProperties: FC<Props> = ({ className, token, ...props }) => {
  const api = useEditorApi()
  const { apiTokenMap, tokens } = useEditorState()
  const defaultValues = useMemo(() => toFormData(token), [token])
  const apiTokenOptions = useMemo(() => ApiToken.mapToOptions(apiTokenMap), [apiTokenMap])
  const scopeTokenMap = Token.getTokenScopeMap(token, tokens)
  const availableApiTokenMap = ApiToken.getScopeMap(apiTokenMap)

  const scopeNames = new Set([...Object.keys(scopeTokenMap), ...Object.keys(availableApiTokenMap)])

  const update = useCallback(
    ({ token_id, ...values }: Record<string, any>) => {
      const data = fromData(values)
      api?.updateToken?.(data, token_id)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token.token_id],
  )

  if (!token) return null

  const isTokenStatic = Token.isTokenStatic(token)
  const isSignature = !isTokenStatic && Token.isSignature(token)
  const isDate = Token.isDate(token)
  const isNumber = Token.isNumber(token)
  const isText = !isTokenStatic && Token.isText(token)
  const isValueAllowed = !isTokenStatic && isNumber
  const isReadOnlyAllowed = isValueAllowed
  const isLabelAllowed = !isSignature
  const isDescriptionAllowed = !isSignature
  const isReferenceAllowed = !isTokenStatic && !isSignature && !isDate

  const conditions = Token.getConditions(token)

  return (
    <Card
      {...props}
      className={cn(styles.toolbar, className)}
      data-role={token.role.toLowerCase()}
      onKeyDown={stopPropagation}
    >
      <div>
        <div className={styles.title}>
          {!isTokenStatic && <RoleIcon role={token.role} />}
          <span>{Token.getGenericLabel(token, apiTokenMap)}</span>
          {typeof token._index === 'number' && (
            <span className={styles.other}>#{token._index}</span>
          )}
        </div>
      </div>

      {token._fixed && <ItemFixes token={token} key={token.name} />}

      <Form
        onSubmit={noop}
        onChange={update}
        className={styles.form}
        defaultValues={defaultValues}
        instantValidation
      >
        <HiddenField name="token_id" />

        {isTokenStatic && (
          <div className={styles.name}>
            <span>Name</span>
            <span>{token.name}</span>
          </div>
        )}

        {isTokenStatic && (
          <Select
            options={apiTokenOptions}
            name="name"
            label="API Token"
            className={styles.field}
            required
          />
        )}
        {!isTokenStatic && (
          <Select
            label="Role"
            name="role"
            options={TemplateCustomToken.ROLE_OPTIONS}
            className={styles.field}
          />
        )}

        {!isTokenStatic && isSignature && (
          <Select
            label="Subtype"
            name="subtype"
            options={UserSignature.OPTIONS}
            className={styles.field}
          />
        )}

        {!isTokenStatic && isLabelAllowed && (
          <TextField label="Label" name="label" className={styles.field} />
        )}
        {!isTokenStatic && (isReferenceAllowed || token.name) && (
          <TextField
            label={
              <span
                data-tooltip-content="Custom name that can be referenced in formulas."
                data-tooltip-id={Tooltip.ID}
              >
                Reference&nbsp;ⓘ
              </span>
            }
            name="name"
            className={styles.field}
            pattern="^[a-zA-Z_][a-zA-Z0-9_]*$"
            validationMessages={NAME_VALIDATION_MESSAGES}
            validation={
              isReferenceAllowed ? undefined : (value) => (!value ? null : 'Must be empty')
            }
          />
        )}
        {!isTokenStatic && isText && (
          <TextField label="Prefilled Text" name="prefilled_text" className={styles.field} />
        )}
        {!isTokenStatic && isDescriptionAllowed && (
          <TextAreaField label="Description" name="description" className={styles.field} />
        )}

        {!isTokenStatic && (
          <CheckboxField
            label="Required?"
            name="required"
            className={styles.checkbox}
            defaultValue={isSignature ? true : token.required}
            readOnly={isSignature}
          />
        )}
        {!isTokenStatic && isDate && (
          <FormValue<Data>>
            {({ values }) =>
              values.role === Token.Role.ADMIN ? (
                <></>
              ) : (
                <CheckboxField
                  name="lock_to_sign_date"
                  label="Lock to sign date"
                  className={styles.checkbox}
                />
              )
            }
          </FormValue>
        )}
        {!isTokenStatic && isReadOnlyAllowed && (
          <CheckboxField
            label="Read-only?"
            name="read_only"
            className={styles.checkbox}
            defaultValue={token.read_only}
          />
        )}

        {isNumber && (
          <FormatNumber
            token={token}
            fieldClassName={styles.field}
            checkboxClassName={styles.checkbox}
          />
        )}
        {isDate && <FormatDate token={token} fieldClassName={styles.field} />}

        {!isTokenStatic && (
          <Card.Section
            title="Calculated Props"
            {...(conditions ? { collapsable: true } : { expandable: true })}
            className={styles.calculated}
          >
            <Card.Heading className={styles.subtitle}>Variables/Constants in Scope</Card.Heading>
            <CurrentScope
              tokenMap={scopeTokenMap}
              apiTokenMap={availableApiTokenMap}
              conditions={conditions}
            />
            <Card.Heading className={styles.subtitle}>Formula Props</Card.Heading>
            <FormulaField name="_hidden" label="Hidden" scope={scopeNames} />
            {isValueAllowed && <FormulaField name="_value" label="Value" scope={scopeNames} />}
          </Card.Section>
        )}

        {!Token.isRadioToken(token) && (
          <Card.Section expandable title="Size & Position">
            <fieldset className={styles.position}>
              <NumberField name="x" label="X" className={styles.field} step={1} min={0} />
              <NumberField name="y" label="Y" className={styles.field} step={1} min={0} />
              <NumberField name="width" label="W" className={styles.field} step={1} min={10} />
              <NumberField name="height" label="H" className={styles.field} step={1} min={10} />
            </fieldset>
          </Card.Section>
        )}
      </Form>

      {!isTokenStatic && Token.isRadioToken(token) && <ToolboxTokenRadios token={token} />}
    </Card>
  )
}

const stopPropagation = (event: React.KeyboardEvent) => {
  event.stopPropagation()
}

const noop = () => {}

const toFormData = (token: Token): Data => {
  const conditional_script = parseJSON(token.conditional_script)
  return {
    token_id: token.token_id,
    label: token.label,
    role: token.role,
    required: token.required ?? false,
    read_only: token.read_only ?? false,
    description: token.description,
    x: Math.round(token.x),
    y: Math.round(token.y),
    width: Math.round(token.width),
    height: Math.round(token.height),
    ...(token.name && { name: token.name }),
    ...(Token.isDate(token) && { lock_to_sign_date: token.lock_to_sign_date }),
    ...(Token.isDate(token) && tokenToDateData(token)),
    ...(Token.isNumber(token) && tokenToNumberData(token)),
    ...(Token.isSignature(token) && { subtype: token.subtype }),
    ...(Token.isText(token) && { prefilled_text: token.prefilled_text }),
    _hidden: conditional_script?.hidden,
    _value: conditional_script?.value,
  }
}
const fromData = ({
  _hidden,
  _value,
  _format_date,
  _format_comma,
  _format_decimals,
  _format_postfix,
  _format_prefix,
  ...data
}: Partial<Data>): Partial<Token> => {
  const hidden = _hidden?.trim()
  const value = _value?.trim()
  const conditional_script = JSON.stringify({
    ...(hidden && { hidden }),
    ...(value && { value }),
  })
  const dateFormat = { _format_date }
  const numberData = { _format_comma, _format_decimals, _format_postfix, _format_prefix }
  return {
    ...data,
    conditional_script,
    ...(hasDateData(dateFormat) && { format: dateDataToFormat(dateFormat) }),
    ...(hasNumberData(numberData) && { format: numberDataToFormat(numberData) }),
  }
}

const parseJSON = (json?: string) => {
  if (!json) return
  try {
    const result = JSON.parse(json)
    return typeof result === 'object' ? result : {}
  } catch (e) {
    return {}
  }
}

const NAME_VALIDATION_MESSAGES = {
  patternMismatch: 'Must start with a letter and contain only letters, numbers, and underscores.',
}
