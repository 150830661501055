import { PdfDocument } from '@rello/pdf'
import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import { WorkspaceWarn } from './workspace-warn'
import styles from './workspace.module.scss'
import { WorkspacePage } from '../page'
import { useEditorApi, useEditorState } from '../state'

interface Props extends Omit<ComponentProps<'div'>, 'children'> {
  pdf: Blob
}
export const Workspace: FC<Props> = ({ pdf, className, ...props }) => {
  const { pages } = useEditorState()
  const api = useEditorApi()

  return (
    <div {...props} className={cn(styles.workspace, className)}>
      <WorkspaceWarn />
      <PdfDocument
        pdf={pdf}
        onLoadPages={api?.setPages}
        className={styles.document}
        onLoadError={errorHandler}
      >
        {pages.map((page) => (
          <WorkspacePage
            key={page.id}
            pageWidth={page.width}
            pageHeight={page.height}
            id={page.id}
          />
        ))}
      </PdfDocument>
    </div>
  )
}

const errorHandler = (error: Error) => {
  throw new Error('Unable to load PDF', { cause: error })
}
