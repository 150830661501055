import { Token } from 'api/template'
import { FC } from 'react'
import { Card, CheckboxField, NumberField, TextField, useFormValues } from 'ui'
import styles from './format-number.module.scss'

export interface NumberData {
  _format_comma?: boolean
  _format_decimals?: number
  _format_prefix?: string
  _format_postfix?: string
}
interface Props {
  token: Token
  fieldClassName?: string
  checkboxClassName?: string
}
export const FormatNumber: FC<Props> = ({ token, fieldClassName, checkboxClassName }) => {
  const data = useFormValues<NumberData>()
  const format = Token.createNumberFormatter(numberDataToFormat(data))
  const samples = format ? [format(1234.56), format(10)] : undefined

  return (
    <Card.Section
      title={`Formatting${samples ? ` (${samples.join('; ')})` : ''}`}
      {...(token.format ? { expandable: true } : { collapsable: true })}
      className={styles.section}
    >
      <fieldset>
        <CheckboxField name="_format_comma" label="Commas" className={checkboxClassName} />
        <NumberField
          name="_format_decimals"
          label="Decimals"
          className={fieldClassName}
          step={1}
          min={0}
          max={100}
        />
        <TextField name="_format_prefix" label="Prefix" className={fieldClassName} />
        <TextField name="_format_postfix" label="Postfix" className={fieldClassName} />
      </fieldset>
    </Card.Section>
  )
}

export const tokenToNumberData = (token: Token.Num): NumberData => {
  const isValidConfig =
    token.format &&
    Object.keys(token.format).every((key) =>
      ['comma', 'decimals', 'prefix', 'postfix'].includes(key),
    )
  const format = isValidConfig ? token.format : Token.DEFAULT_NUMBER_FORMAT
  return {
    _format_comma: format?.comma,
    _format_decimals: format?.decimals,
    _format_prefix: format?.prefix,
    _format_postfix: format?.postfix,
  }
}

export const hasNumberData = ({
  _format_comma,
  _format_decimals,
  _format_prefix,
  _format_postfix,
}: NumberData) => {
  return (
    typeof _format_decimals === 'number' ||
    typeof _format_comma === 'boolean' ||
    !!_format_prefix ||
    !!_format_postfix
  )
}

export const numberDataToFormat = ({
  _format_comma,
  _format_decimals,
  _format_prefix,
  _format_postfix,
}: NumberData) => {
  return {
    comma: _format_comma ?? Token.DEFAULT_NUMBER_FORMAT.comma,
    decimals: _format_decimals ?? Token.DEFAULT_NUMBER_FORMAT.decimals,
    prefix: _format_prefix || Token.DEFAULT_NUMBER_FORMAT.prefix,
    postfix: _format_postfix || Token.DEFAULT_NUMBER_FORMAT.postfix,
  }
}
