import { Radio, Token } from 'api/template'
import { IconFieldRadioChecked, IconFieldRadioUnchecked } from 'icons'
import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import styles from './page-item.module.scss'

interface Props extends Omit<ComponentProps<'div'>, 'children' | 'onSelect'> {
  token: Token.TokenRadio
  radio: Radio
}
export const PageRadioOption: FC<Props> = ({ className, radio, token, ...props }) => {
  return (
    <div
      {...props}
      data-role={token.role.toLowerCase()}
      className={cn(styles.item, styles.radio, className)}
      data-fixed={radio._fixed ? 'true' : undefined}
    >
      {radio.checked === '0' ? (
        <IconFieldRadioUnchecked className={styles.icon} />
      ) : (
        <IconFieldRadioChecked className={styles.icon} />
      )}
    </div>
  )
}
