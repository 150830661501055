import { MouseEventHandler, useCallback } from 'react'
import { useSelected } from './use-selected'
import { SelectionModifier, WorkspaceSelection } from '../state'

export const useSelectableProps = (selection: WorkspaceSelection) => {
  const [selected, select] = useSelected(selection)
  const onClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      const cmd = event.metaKey || event.ctrlKey
      const selected = event.currentTarget.dataset.selected === 'true'
      const modifier = cmd
        ? selected
          ? SelectionModifier.REMOVE
          : SelectionModifier.ADD
        : undefined

      select(modifier)
      if (!modifier || modifier === SelectionModifier.ADD) {
        event.currentTarget.focus?.()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return {
    'data-selectable': 'true',
    'data-selected': selected ? 'true' : undefined,
    onClick,
    tabIndex: 0,
  }
}
