import { FC } from 'react'
import { ToolboxAlignment } from './toolbox-alignment'
import { useEditorState } from '../state'
import { ToolboxTokenProperties } from '../toolbox-token-properties'

interface Props {}
export const ToolboxSelection: FC<Props> = () => {
  const state = useEditorState()

  if (!state.selection) return null

  if (state.selection?.length === 1) {
    const token = state.tokens.find((token) => token.token_id === state.selection?.[0].token_id)
    if (!token) return null
    return <ToolboxTokenProperties key={token.token_id} token={token} />
  }

  return <ToolboxAlignment />
}
