import { PdfPage } from '@rello/pdf'
import { FC, ComponentProps, useCallback, useRef } from 'react'
import { PageDropzone } from './page-dropzone'
import styles from './page.module.scss'
import { PageTokens } from '../page-tokens'

interface Props extends Omit<ComponentProps<'div'>, 'id' | 'width' | 'height'> {
  id: number
  pageWidth: number
  pageHeight: number
}

export const WorkspacePage: FC<Props> = ({ pageWidth, pageHeight, id }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const focusSelectedElement = useCallback(() => {
    const container = containerRef.current
    if (!container) return
    const element = container.querySelector('[data-selected="true"]')
    if (element) {
      ;(element as HTMLElement).focus?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PdfPage pageHeight={pageHeight} pageWidth={pageWidth} id={id}>
      {({ zoom }) => (
        <div ref={containerRef} className={styles.tokens}>
          <PageDropzone pageId={id} zoom={zoom} onSelectionEnd={focusSelectedElement} />
          <PageTokens zoom={zoom} pageId={id} />
        </div>
      )}
    </PdfPage>
  )
}
