import { Token } from 'api/template'
import {
  IconCalendar,
  IconFieldCheckboxChecked,
  IconFieldRadioChecked,
  IconFieldSignature,
  IconFieldText,
} from 'icons'
import { FC } from 'react'
import styles from './field-type-icon.module.scss'

interface Props {
  type: Token.Type
}

export const FieldTypeIcon: FC<Props> = ({ type }) => {
  switch (type) {
    case Token.Type.TEXT:
      return <IconFieldText className={styles.icon} />
    case Token.Type.NUMBER:
      return <IconFieldText className={styles.icon} />
    case Token.Type.SIGNATURE:
      return <IconFieldSignature className={styles.icon} />
    case Token.Type.DATE:
      return <IconCalendar className={styles.icon} />
    case Token.Type.RADIO:
      return <IconFieldRadioChecked className={styles.icon} />
    case Token.Type.CHECKBOX:
      return <IconFieldCheckboxChecked className={styles.icon} />
  }
}
