import { Token } from 'api/template'
import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import styles from './page-item.module.scss'
import { FieldTypeIcon } from '../icons/field-type-icon'

interface Props extends Omit<ComponentProps<'div'>, 'children'> {
  token: Token.Checkbox
}
export const PageTokenCheckbox: FC<Props> = ({ className, token, ...props }) => {
  return (
    <div
      {...props}
      className={cn(styles.item, styles.checkbox, className)}
      data-role={token.role.toLowerCase()}
      data-fixed={token._fixed ? 'true' : undefined}
    >
      <FieldTypeIcon type={token.type} />{' '}
    </div>
  )
}
