import { FC, ComponentProps, useCallback, MouseEventHandler } from 'react'
import { Card, IconButton } from 'ui'
import { cn } from 'utils'
import {
  IconAlignHorizontalLeft,
  IconAlignHorizontalCenter,
  IconAlignHorizontalRight,
  IconAlignVerticalBottom,
  IconAlignVerticalCenter,
  IconAlignVerticalTop,
} from './icons'
import styles from './toolbox-alignment.module.scss'
import { useEditorApi, Alignment } from '../state'

interface Props extends Omit<ComponentProps<typeof Card>, 'children'> {}
export const ToolboxAlignment: FC<Props> = ({ className, ...props }) => {
  const api = useEditorApi()
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const alignment = event.currentTarget.value as Alignment
      api?.alignSelection(alignment)
    },
    [api],
  )
  return (
    <Card {...props} className={cn(styles.card, className)}>
      <Card.Section title="Align" className={styles.align}>
        <IconButton className={styles.button} onClick={handleClick} value={Alignment.H_LEFT}>
          <IconAlignHorizontalLeft />
        </IconButton>
        <IconButton className={styles.button} onClick={handleClick} value={Alignment.H_CENTER}>
          <IconAlignHorizontalCenter />
        </IconButton>
        <IconButton className={styles.button} onClick={handleClick} value={Alignment.H_RIGHT}>
          <IconAlignHorizontalRight />
        </IconButton>
        <IconButton className={styles.button} onClick={handleClick} value={Alignment.V_TOP}>
          <IconAlignVerticalTop />
        </IconButton>
        <IconButton className={styles.button} onClick={handleClick} value={Alignment.V_CENTER}>
          <IconAlignVerticalCenter />
        </IconButton>
        <IconButton className={styles.button} onClick={handleClick} value={Alignment.V_BOTTOM}>
          <IconAlignVerticalBottom />
        </IconButton>
      </Card.Section>
    </Card>
  )
}
