import { Token } from 'api/template'
import { FC } from 'react'
import { Card, TextField, useFormValues } from 'ui'
import styles from './format-date.module.scss'

interface Props {
  token: Token
  fieldClassName?: string
}
export const FormatDate: FC<Props> = ({ token, fieldClassName }) => {
  const data = useFormValues<DateData>()
  const options = hasDateData(data) ? dateDataToFormat(data) : undefined

  return (
    <Card.Section
      title={`Formatting${
        options ? ` (${sample(options)}, ${sample(options, new Date(2024, 0, 1))})` : ''
      }`}
      {...(token.format ? { expandable: true } : { collapsable: true })}
      className={styles.section}
    >
      <ul className={styles.description}>
        <li>
          <code>yy</code> Year short format (24)
        </li>
        <li>
          <code>yyyy</code> Year long format (2024)
        </li>
        <li>
          <code>M</code> Month no zero padding (9)
        </li>
        <li>
          <code>MM</code> Month with zero padding (09)
        </li>
        <li>
          <code>MMM</code> Month short format (Sep)
        </li>
        <li>
          <code>MMMM</code> Month long format (September)
        </li>
        <li>
          <code>d</code> Date no zero padding (3)
        </li>
        <li>
          <code>dd</code> Date with zero padding (03)
        </li>
      </ul>
      <fieldset>
        <TextField label="Date" name="_format_date" required className={fieldClassName} />
      </fieldset>
    </Card.Section>
  )
}

const sample = (options: Token.Date['format'], date = new Date()) =>
  Token.createDateFormatter(options)(date)

export interface DateData {
  _format_date?: string
}

export const tokenToDateData = ({ format }: Token.Date): DateData => {
  return { _format_date: format?.date ?? Token.DEFAULT_DATE_FORMAT }
}

export const hasDateData = (data: DateData) => !!data._format_date

export const dateDataToFormat = ({ _format_date }: DateData): Token.Date['format'] => {
  return {
    date: _format_date ?? Token.DEFAULT_DATE_FORMAT,
  }
}
