import { ApiToken, Token } from 'api/template'
import { FC, Fragment, MouseEventHandler, useCallback } from 'react'
import { Tooltip } from 'ui'
import { cn } from 'utils'
import { every, pickAll } from 'utils/compose'
import { alertErrorMessage, alertSuccessMessage } from 'utils/toast'
import styles from './current-scope.module.scss'

interface Props {
  className?: string
  tokenMap: Record<string, Token>
  apiTokenMap: Record<string, ApiToken>
  conditions?: Token.Conditions
}
export const CurrentScope: FC<Props> = ({ className, tokenMap, apiTokenMap, conditions }) => {
  const usedVariables =
    conditions && Object.values(conditions).flatMap((expression) => expression.variables() ?? [])

  const handleClick = useCallback<MouseEventHandler<HTMLSpanElement>>((event) => {
    const text = event.currentTarget?.dataset?.name
    text &&
      navigator.clipboard
        .writeText(text)
        .then(() => alertSuccessMessage('Copied'))
        .catch(alertErrorMessage)
  }, [])

  const apiTokens = Object.values(apiTokenMap).filter(
    every(ApiToken.byRole(Token.Role.API), ApiToken.canReference),
  )

  return (
    <div className={cn(styles.scope, className)}>
      {apiTokens.map((apiToken) => (
        <Fragment key={apiToken.name}>
          <span
            data-tooltip-id={`tooltip-${apiToken.name}`}
            data-role={Token.Role.API.toLowerCase()}
            data-name={apiToken.name}
            className={cn(
              styles.token,
              styles.apitoken,
              apiToken.name in tokenMap && styles.overridden,
              usedVariables?.includes(apiToken.name) && styles.used,
            )}
            onClick={handleClick}
          >
            {apiToken.name}
            <Tooltip
              id={`tooltip-${apiToken.name}`}
              className={styles.tooltip}
              data-role={Token.Role.API.toLowerCase()}
              float={false}
              positionStrategy="absolute"
            >
              <div className={styles.tooltipcontent}>
                <div>
                  <strong>{apiToken.label}</strong>
                </div>
                <div>
                  type: <strong>{apiToken.data_type}</strong>
                </div>
                <div>{apiToken.description}</div>
              </div>
            </Tooltip>
          </span>
        </Fragment>
      ))}
      {Object.values(tokenMap).map((token) => (
        <Fragment key={token.token_id}>
          <span
            data-tooltip-id={`tooltip-${token.token_id}`}
            className={cn(
              styles.token,
              styles.local,
              token.name && usedVariables?.includes(token.name) && styles.used,
            )}
            data-role={token.role.toLowerCase()}
            data-name={token.name}
            onClick={handleClick}
          >
            {token.name}
          </span>
          <Tooltip
            id={`tooltip-${token.token_id}`}
            className={styles.tooltip}
            data-role={token.role.toLowerCase()}
          >
            <div className={styles.tooltipcontent}>
              <div>
                <strong>#{token._index}</strong> {token.label}
              </div>

              <div>
                <strong>Role:</strong> {token.role}
              </div>
              {token.required && (
                <div>
                  <strong>Required</strong>
                </div>
              )}
              {token.conditional_script && (
                <div>
                  <strong>Has Formula Fields</strong>
                </div>
              )}
              <div>
                <strong>Type:</strong> {token.type}
              </div>
              {Token.isRadioToken(token) && (
                <div>
                  <strong>Options:</strong>{' '}
                  {pickAll('value', token.radio)
                    .map((v) => `"${v}"`)
                    .join(', ')}
                </div>
              )}
              <div>{token.description}</div>
            </div>
          </Tooltip>
        </Fragment>
      ))}
    </div>
  )
}
