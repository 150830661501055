import { ComponentProps, useRef } from 'react'
import Moveable from 'react-moveable'
import { cn } from 'utils'
import styles from './page-selection.module.scss'
import { getPageItemStyle } from '../util'

export interface PageItem {
  x: number
  y: number
  height: number
  width: number
  page_number: number
  resize?: ('n' | 'nw' | 'ne' | 's' | 'se' | 'sw' | 'e' | 'w')[]
  keepAspectRatio?: boolean
}

interface Props<T extends PageItem = PageItem>
  extends Omit<ComponentProps<'div'>, 'onKeyDown' | 'onSelect' | 'onFocus' | 'onResize'> {
  item: T
  zoom: number
  onSelectionMove?: (update: T) => any
  onSelectionResize?: (update: T) => any
}
export function PageSelection<T extends PageItem>({
  className,
  item,
  zoom,
  onSelectionMove,
  onSelectionResize,
  children,
  ...props
}: Props<T>) {
  const contentRef = useRef<HTMLDivElement>(null)

  const style = getPageItemStyle(item, zoom)
  const resizeProps: Partial<ComponentProps<typeof Moveable>> = onSelectionResize
    ? {
        resizable: true,
        renderDirections: item.resize ?? ['n', 'w', 'e', 's'],
        keepRatio: item.keepAspectRatio ?? false,
        onResize: (e) => {
          contentRef.current?.style.setProperty('width', `${e.width}px`)
          contentRef.current?.style.setProperty('height', `${e.height}px`)
          contentRef.current?.style.setProperty('transform', e.style.transform)
        },
        onResizeEnd: (e) => {
          if (!e.lastEvent) return
          const [dx, dy] = e.lastEvent.drag?.translate ?? [0, 0]
          const { width, height } = e.lastEvent
          const update = {
            x: Math.round(dx / zoom),
            y: Math.round(dy / zoom),
            width: Math.round(width / zoom),
            height: Math.round(height / zoom),
          }
          onSelectionResize?.({
            ...item,
            ...update,
          })
          contentRef.current?.style.removeProperty('transform')
        },
      }
    : {}

  const dragProps: Partial<ComponentProps<typeof Moveable>> = {
    draggable: true,
    throttleDrag: 0.5,
    edgeDraggable: false,
    onDrag: (e) => {
      contentRef.current?.style.setProperty('transform', e.transform)
    },
    onDragEnd: (e) => {
      const [dx, dy] = e.lastEvent?.translate ?? [0, 0]
      if (dx || dy) {
        contentRef.current?.style.removeProperty('transform')
        setTimeout(() => {
          onSelectionMove?.({
            ...item,
            x: Math.round(dx / zoom),
            y: Math.round(dy / zoom),
          })
        }, 0)
      }
    },
  }

  return (
    <>
      <div
        {...props}
        tabIndex={0}
        className={cn(styles.selection, className)}
        data-selected="true"
        ref={contentRef}
        style={style}
      />
      <Moveable
        target={contentRef}
        useResizeObserver
        useMutationObserver
        // RESIZE
        {...resizeProps}
        // DRAG
        {...dragProps}
        // SNAP
        snappable
        snapGridWidth={1}
        snapGridHeight={1}
        isDisplayGridGuidelines={false}
        className={styles.moveable}
      />
    </>
  )
}
