import { Parser, type Expression } from 'expr-eval'
import { every, not, pickAll } from 'utils/compose'
import { ApiToken } from './api-token'
import { Radio } from './radio'
import { TemplateCustomToken } from './template-custom-token.admin'
import { Template } from './template.admin'
import { UserSignature } from '../user-signature'

export interface Token {
  _index: number
  _fixed?: Partial<Token>
  color?: string // TODO: exact name?
  conditional_script?: string
  format?: object
  description?: string
  height: number
  label?: string
  name?: string
  page_number: number
  radio?: Radio[]
  read_only?: boolean
  required?: boolean
  role: Token.Role
  size?: number // TODO: exact name?
  token_id: string
  type: Token.Type
  width: number
  x: number
  y: number

  // signature:
  subtype?: UserSignature.Type
  // date:
  lock_to_sign_date?: boolean
}

export namespace Token {
  export type IdField = 'token_id'
  export type Id = Pick<Token, IdField>

  export const enum Type {
    TEXT = 'text',
    NUMBER = 'number',
    DATE = 'date',
    CHECKBOX = 'checkbox',
    SIGNATURE = 'signature',
    RADIO = 'radiobutton',
  }
  export const isType = (type: string): type is Type =>
    [Type.TEXT, Type.NUMBER, Type.DATE, Type.CHECKBOX, Type.SIGNATURE, Type.RADIO].includes(
      type as Type,
    )

  export const enum Role {
    FIRST_SIGNER = 'FirstSigner',
    T1 = 'Tenant1',
    T2 = 'Tenant2',
    T3 = 'Tenant3',
    T4 = 'Tenant4',
    T5 = 'Tenant5',
    T6 = 'Tenant6',
    G1 = 'Guarantor1',
    G2 = 'Guarantor2',
    G3 = 'Guarantor3',
    G4 = 'Guarantor4',
    G5 = 'Guarantor5',
    G6 = 'Guarantor6',
    OWNER = 'Owner',
    ADMIN = 'Admin',
    API = 'API',
  }
  export const isRole = (role: string): role is Role =>
    [
      Role.FIRST_SIGNER,
      Role.T1,
      Role.T2,
      Role.T3,
      Role.T4,
      Role.T5,
      Role.T6,
      Role.G1,
      Role.G2,
      Role.G3,
      Role.G4,
      Role.G5,
      Role.G6,
      Role.OWNER,
      Role.ADMIN,
      Role.API,
    ].includes(role as Role)

  const getRoleOrder = (role: Role) => {
    if (role === Role.API) return 1
    if (role === Role.ADMIN) return 2
    if (role === Role.FIRST_SIGNER) return 3
    if (role.startsWith('Tenant')) return 4
    if (role.startsWith('Guarantor')) return 5
    if (role === Role.OWNER) return 6
    throw new Error(`Unknown role: ${role}`)
  }

  export interface ConditionalProps {
    hidden?: boolean
    value?: number | string
  }
  export type ConditionalProp = keyof ConditionalProps
  export type ConditionalPropFormulas = Partial<Record<keyof ConditionalProps, string>>
  export const isValidConditionalProp = (prop: string): prop is ConditionalProp =>
    ['hidden', 'value'].includes(prop)
  export type Conditions = Record<ConditionalProp, Expression>

  export interface Text extends Token {
    type: Token.Type.TEXT
    prefilled_text?: string
  }
  export interface Num extends Token {
    type: Token.Type.NUMBER
    default_value?: string
    min?: number
    max?: number
    format?: {
      comma?: boolean
      decimals?: number
      prefix?: string
      postfix?: string
    }
  }
  export interface Date extends Token {
    type: Token.Type.DATE
    format?: { date?: string }
  }
  export interface Checkbox extends Token {
    type: Token.Type.CHECKBOX
  }
  export interface Signature extends Token {
    type: Token.Type.SIGNATURE
    subtype?: UserSignature.Type
  }

  export interface TokenRadio extends Token {
    type: Token.Type.RADIO
    radio: Radio[]
  }

  export type TokenWithSingleItem = Text | Date | Signature | Checkbox

  type RawTokenWithSingleItem = Omit<TokenWithSingleItem, '_index' | '_fixed'>
  export type RawRadio = Omit<Radio, '_fixed' | '_radioIndex' | '_index'>
  type RawRadioToken = Omit<TokenRadio, '_index' | 'fixed' | 'radio'> & { radio: RawRadio[] }

  export type Raw = RawTokenWithSingleItem | RawRadioToken

  export const sortTokens = <T extends Pick<Token, 'page_number' | 'x' | 'y' | 'role'>>(
    tokens: T[],
  ): T[] =>
    [...tokens].sort(
      (a, b) =>
        getRoleOrder(a.role) - getRoleOrder(b.role) ||
        a.page_number - b.page_number ||
        a.y - b.y ||
        a.x - b.x,
    )

  export const init = (tokens: (Token | Raw)[], template: Template.Id): Token[] => {
    if (!tokens?.length) return []
    return sortTokens(tokens)
      .map((t, _index) => {
        const role = isRole(t.role) ? t.role : Role.API
        if (role === Role.API && !t.name) return undefined

        const token = {
          ...t,
          token_id: t.token_id ?? createId(template),
          label: t.label,
          type: t.type,
          role,
          _index,
        } as Token

        if (isRadioToken(token)) {
          return (token.radio?.length ?? 0) < 2 ? undefined : initTokenRadios(token)
        }
        return token
      })
      .filter(Boolean) as Token[]
  }

  export const initTokenRadios = (token: TokenRadio): TokenRadio => {
    token.radio = token.radio?.map((r, _radioIndex) => ({
      ...r,
      _index: token._index,
      _radioIndex,
      radio_id: r.radio_id ?? createRadioId(token),
    })) as Radio[]
    return token
  }

  interface CreateFieldOptions {
    x: number
    y: number
    name?: string
    page_number: number
    _index: number
  }
  const RADIO_SIZE = 17
  const RADIO_DIMENSION = {
    width: RADIO_SIZE,
    height: RADIO_SIZE,
  }
  const TEXT_DIMENSION = {
    width: 100,
    height: 14,
  }
  export const SIGNATURE_DIMENSION = {
    width: Math.round(UserSignature.SIZE.WIDTH / 10),
    height: Math.round(UserSignature.SIZE.HEIGHT / 10),
  }

  export const byId = (id: string) => (token: Id) => token.token_id === id
  export const byRole = (role: Role) => (token: Token | Token.Raw) => token.role === role
  export const pickId = (t: Id) => t.token_id

  export const byRadioId = (id: string) => (radio: Pick<Radio, 'radio_id'>) => radio.radio_id === id

  export const createRadio = (
    data: Pick<Radio, 'value' | 'x' | 'y' | 'page_number' | '_radioIndex'>,
    tokenRadio: Pick<Token, 'token_id' | '_index'>,
  ): Radio => {
    return {
      ...RADIO_DIMENSION,
      checked: '0',
      created: 0,
      size: Math.floor(RADIO_SIZE / 2),
      radio_id: createRadioId(tokenRadio),
      ...data,
      _index: tokenRadio._index,
    }
  }
  export const createToken = (
    options: CreateFieldOptions,
    toolField: TemplateCustomToken.Field,
    template: Template.Id,
  ): Token => {
    const base: Token = {
      ...([Type.CHECKBOX, Type.RADIO].includes(toolField.type) ? RADIO_DIMENSION : TEXT_DIMENSION),
      ...toolField.config,
      name: toolField.name,
      ...options,
      token_id: createId(template),
      type: toolField.type,
      ...(toolField.type === Type.SIGNATURE && SIGNATURE_DIMENSION),
    }
    if (toolField.type !== Type.RADIO) {
      return base
    }
    return {
      ...base,
      type: toolField.type,
      radio: [
        createRadio(
          {
            value: 'Yes',
            _radioIndex: 0,
            ...options,
          },
          base,
        ),
        createRadio(
          {
            value: 'No',
            _radioIndex: 1,
            ...options,
            y: options.y + 100,
          },
          base,
        ),
      ],
    }
  }

  export const getShortRoleLabel = (token: Token) =>
    token.role
      .replace(/Tenant(\d)/, `T$1`)
      .replace(/Guarantor(\d)/, `G$1`)
      .replace(Role.FIRST_SIGNER, '1st')

  const shortId = (id: string) => id.replace(/-.*/, '')
  export const createId = (t: Template.Id) =>
    `${shortId(t.template_id)}:${window.crypto.randomUUID()}`
  export const createRadioId = (token: Id) =>
    `${shortId(token.token_id)}:${window.crypto.randomUUID()}`

  export const isRadioToken = (t?: Token | Radio): t is TokenRadio =>
    (t as Token)?.type === Type.RADIO
  export const isText = (t: Token | Radio): t is Text => (t as Token).type === Type.TEXT
  export const isNumber = (t: Token | Radio): t is Num => (t as Token).type === Type.NUMBER
  export const isDate = (t: Token | Radio): t is Date => (t as Token).type === Type.DATE
  export const isCheckbox = (t: Token | Radio): t is Checkbox => (t as Token).type === Type.CHECKBOX
  export const isSignature = (t: Token | Radio): t is Signature =>
    (t as Token).type === Type.SIGNATURE

  export const isTokenStatic = (t?: Pick<Token, 'role'>) => t?.role === Role.API

  export const byPageNumber = (page: number) => (t: Pick<Token, 'page_number'>) =>
    t.page_number === page

  export const DIVIDER = '-' as const
  export const isTokenOfName = (name?: string) => (token?: Token) => {
    if (!token || !name) return false
    return token.name === name || getBaseName(token) === name
  }
  const getBaseName = (token?: Pick<Token, 'name'>) =>
    token?.name ? token.name.replace(/-.*$/, '') : undefined

  export const getGenericLabel = (token: Token, apiTokenMap?: Record<string, ApiToken>) => {
    if (token.label) return token.label
    if (isTokenStatic(token)) {
      if (token.name && apiTokenMap?.[token.name]?.label) {
        return apiTokenMap[token.name].label
      }
      return token.name
    }
    switch (token.type) {
      case Token.Type.TEXT:
        return token.role === Token.Role.API && token.name
          ? nameToLabel(token.name)
          : `${getShortRoleLabel(token)} Text`
      case Token.Type.NUMBER:
        return token.role === Token.Role.API && token.name
          ? nameToLabel(token.name)
          : `${getShortRoleLabel(token)} Number`
      case Token.Type.SIGNATURE:
        return `${getShortRoleLabel(token)} ${
          UserSignature.TYPE_SHORT[token.subtype || UserSignature.Type.SIGNATURE]
        }`
      case Token.Type.DATE:
        return `${Token.getShortRoleLabel(token)} ${token.lock_to_sign_date ? 'Sign Date' : 'Date'}`

      case Token.Type.CHECKBOX:
        return `${getShortRoleLabel(token)} Checkbox`

      case Token.Type.RADIO:
        return `${getShortRoleLabel(token)} Radio`
    }
  }

  const nameToLabel = (_name: string) => {
    let name = _name.replace(/-.*/, '')
    if (name.startsWith('owner_')) {
      name = name.replace(/^owner_/, "Owner's ")
    } else if (name.startsWith('tenant')) {
      name = name.replace(/^(tenant)(\d)_/, `T$2's `)
    } else if (name.startsWith('guarantor')) {
      name = name.replace(/^(guarantor)(\d)_/, `G$2's `)
    }
    return name.replace('_', ' ')
  }

  export const hasSignatureOfType = (tokens: Token[], type: UserSignature.Type) =>
    tokens.some(
      (token) =>
        Token.isSignature(token) && (token.subtype ?? UserSignature.Type.SIGNATURE) === type,
    )

  export const hasCustomName = (token: Token) => !isTokenStatic(token) && !!token.name

  export const getCustomNames = (tokens: Token[]) => {
    return [...new Set(pickAll('name', tokens.filter(Token.hasCustomName)))]
  }

  export const getConditionalProps = (
    token: Token,
  ): Record<ConditionalProp, string> | undefined => {
    if (typeof token.conditional_script !== 'string' || !token.conditional_script) return undefined
    try {
      const scripts = JSON.parse(token.conditional_script)
      if (!Object.keys(scripts).length) return undefined

      const result = {} as Record<ConditionalProp, string>
      for (const [name, formula] of Object.entries(scripts)) {
        if (typeof formula === 'string' && isValidConditionalProp(name)) {
          result[name] = formula
        }
      }
      return result
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Error parsing token.conditional_script`, token, token.conditional_script)
      return undefined
    }
  }

  export const getConditions = (
    token: Token,
    scope?: Record<string, any>,
  ): Conditions | undefined => {
    const props = getConditionalProps(token)
    if (!props) return
    const result = {} as Record<ConditionalProp, Expression>
    Object.entries(props).forEach(([name, formula]) => {
      try {
        const expression = Parser.parse(formula)
        if (expression) {
          result[name as ConditionalProp] = scope ? expression.simplify(scope) : expression
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(
          `Error parsing formula "${formula}" of ${
            token.label ?? token.name ?? token.token_id
          }, page ${token.page_number} (${token.x},${token.y})`,
          e,
        )
      }
    })
    return result
  }

  /** 
    @returns `undefined` - there was an error\
             `{}`        - no conditions
   */
  export const evaluateConditions = (
    { hidden, ...conditions }: Conditions | undefined = {} as Conditions,
    scope: Record<string, any>,
  ): ConditionalProps | undefined => {
    if (!conditions) return {}

    try {
      if (hidden && hidden.evaluate(scope)) {
        return { hidden: true }
      }
      return Object.fromEntries(
        Object.entries(conditions).map(([name, expression]) => [name, expression.evaluate(scope)]),
      )
    } catch (e) {
      // // eslint-disable-next-line no-console
      // console.warn('Failed to evaluate hidden condition', e)
      return undefined
    }
  }

  export const isReadOnly = (token: Token) => {
    if (isTokenStatic(token)) return true
    if (isDate(token)) return !!token.lock_to_sign_date
    return !!token.read_only
  }

  export const getTokenScopeMap = (token: Token, tokens: Token[]) => {
    const previousTokes = token._index > 0 ? tokens.slice(0, token._index) : []
    // exclude nameless tokens, signatures, dates
    const tokenFilter = every(
      hasCustomName,
      not(isTokenStatic),
      not(isSignature),
      not(isDate), // TODO: add support for dates
    )
    return Object.fromEntries(previousTokes.filter(tokenFilter).map((token) => [token.name, token]))
  }

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const shortMonthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const padZero = (value: number) => (value < 10 ? `0${value}` : `${value}`)

  export const DEFAULT_DATE_FORMAT = 'MM/d/yyyy'

  export const createDateFormatter = (_format?: Date['format']) => {
    const format = _format?.date ?? DEFAULT_DATE_FORMAT
    return (date?: GlobalDate) => {
      if (!date) return ''
      const parts = {
        yy: String(date.getFullYear()).substring(2),
        yyyy: String(date.getFullYear()),
        M: String(date.getMonth() + 1),
        MM: padZero(date.getMonth() + 1),
        MMM: shortMonthNames[date.getMonth()],
        MMMM: monthNames[date.getMonth()],
        d: String(date.getDate()),
        dd: padZero(date.getDate()),
      } as const
      type Part = keyof typeof parts
      return format.replace(/yyyy|yy|MMMM|MMM|MM|M|dd|d/g, (match) =>
        match in parts ? parts[match as Part] : match,
      )
    }
  }

  export const DEFAULT_NUMBER_FORMAT = {
    comma: true,
    decimals: 0,
    prefix: '',
    postfix: '',
  }

  export const createNumberFormatter = (_format?: Num['format']) => {
    const { postfix, comma, decimals, prefix } = { ...DEFAULT_NUMBER_FORMAT, ..._format }
    let formatter: Intl.NumberFormat

    try {
      const options: Intl.NumberFormatOptions = {
        currency: 'USD',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
        useGrouping: !!comma,
      }
      formatter = new Intl.NumberFormat('en-US', options)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Failed to create number formatter', e)
    }
    return (value?: number) => {
      if (!formatter || typeof value !== 'number' || isNaN(value)) return ''
      try {
        return `${prefix}${formatter.format(value)}${postfix}`
      } catch (e) {
        return ''
      }
    }
  }
}

type GlobalDate = Date
