import { Token } from 'api/template'
import { FC, ComponentProps, useCallback } from 'react'
import { HeadsUp, Button } from 'ui'
import { cn } from 'utils'
import { pluralize } from 'utils/pluralize'
import styles from './workspace-warn.module.scss'
import { useEditorApi, useEditorState, getFixedItems, itemToSelection } from '../state'

interface Props extends ComponentProps<'div'> {}
export const WorkspaceWarn: FC<Props> = ({ className, ...props }) => {
  const fixedItems = getFixedItems(useEditorState())
  const api = useEditorApi()

  const findToken = useCallback(() => {
    if (!fixedItems[0]) return
    api?.select(itemToSelection(fixedItems[0]))
    // timeout is needed to wait for React to update the DOM
    setTimeout(() => {
      // scroll to the selected item
      document
        .querySelector('[data-selected="true"]')
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedItems[0]])

  const resolveAll = useCallback(() => {
    if (!fixedItems.length) return
    api?.select(fixedItems.map(itemToSelection))
    api?.updateSelected((item: Token) => ({ ...item, _fixed: undefined }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedItems])

  const deleteAll = useCallback(() => {
    if (!fixedItems.length) return
    api?.select(fixedItems.map(itemToSelection))
    api?.deleteSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedItems])

  if (!fixedItems.length) return null

  return (
    <HeadsUp.Warn {...props} className={cn(styles.warn, className)}>
      {pluralize(fixedItems.length, 'token was', 'tokens were')} misplaced or changed. Please review
      and resolve the warnings.
      <div className={styles.actions}>
        <Button onClick={findToken} theme="secondary" className={styles.button}>
          Find Token
        </Button>
        <Button onClick={resolveAll} theme="warn" className={styles.button}>
          Apply All
        </Button>
        <Button onClick={deleteAll} theme="warn" className={styles.button}>
          Delete All
        </Button>
      </div>
    </HeadsUp.Warn>
  )
}
