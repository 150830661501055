import { IconBack } from 'icons'
import { FC } from 'react'
import { Button, SubmitButton } from 'ui'
import { cn } from 'utils'
import { pluralize } from 'utils/pluralize'
import styles from './lease-builder.module.scss'

interface Props {
  submitLabel: string
  onNext: (dir: 1 | -1) => void
  isFirst?: boolean
  isLast?: boolean
  className?: string
  count?: number
}
export const LeaseBuilderActions: FC<Props> = ({
  className,
  isFirst,
  isLast,
  onNext,
  submitLabel,
  count = 0,
}) => {
  return (
    <div className={cn(styles.actions, className)}>
      {count > 0 && (
        <>
          <p className={styles.info}>Please fill out {pluralize(count, 'field')}</p>
          <Button
            disabled={!!isFirst}
            onClick={() => onNext(-1)}
            theme="secondary"
            className={styles.prev}
          >
            <IconBack />
          </Button>
          <Button disabled={!!isLast} onClick={() => onNext(1)} theme="secondary">
            Next
          </Button>
        </>
      )}
      <SubmitButton disableInvalid className={styles.submit}>
        {submitLabel}
      </SubmitButton>
    </div>
  )
}
