import { Token } from 'api/template'
import { IconFieldText } from 'icons'
import { FC, ComponentProps, CSSProperties } from 'react'
import { cn } from 'utils'
import styles from './page-item.module.scss'
import { RoleIcon } from '../icons/role-icon'
import { useEditorState } from '../state'

interface Props extends Omit<ComponentProps<'div'>, 'children'> {
  token: Token.Text
}
export const PageTokenText: FC<Props> = ({ className, token, style, ...props }) => {
  const { apiTokenMap } = useEditorState()
  const isStatic = Token.isTokenStatic(token)
  return (
    <div
      {...props}
      className={cn(styles.item, styles.text, isStatic && styles.static, className)}
      data-role={token.role.toLowerCase()}
      style={{ ...style, '--size': token.size, '--color': token.color } as CSSProperties}
      data-fixed={token._fixed ? 'true' : undefined}
    >
      {!isStatic && <IconFieldText className={styles.icon} />}
      <RoleIcon role={token.role} />
      <span className={styles.label}>{Token.getGenericLabel(token, apiTokenMap)}</span>
    </div>
  )
}
