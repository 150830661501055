import { Radio, Token } from 'api/template'
import { FC, useCallback } from 'react'
import { Button } from 'ui'
import styles from './toolbox-token-properties.module.scss'
import { useEditorApi } from '../state'

interface Props {
  token: Token
  radio?: Radio
}
export const ItemFixes: FC<Props> = ({ radio, token }) => {
  const api = useEditorApi()

  const resolveFixes = useCallback(() => {
    if (radio) {
      api?.updateRadioById(
        (radio) => ({ ...radio, _fixed: undefined }),
        token.token_id,
        radio.radio_id,
      )
    } else {
      api?.updateToken((token) => ({ ...token, _fixed: undefined }), token.token_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radio, token])

  const deleteItem = useCallback(() => {
    if (radio) {
      api?.deleteRadioById(token.token_id, radio.radio_id)
    } else {
      api?.deleteTokenById(token.token_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token.token_id, radio?.radio_id])

  const item = radio ?? token
  if (!item._fixed) return null

  return (
    <div className={styles.fixes}>
      <span className={styles.fixesTitle}>Autofixed Properties:</span>
      <ul>
        {Object.entries(item._fixed).map(([key, value]) => (
          <li key={key}>
            <span className={styles.fixedLabel}>{key}:</span>{' '}
            <span className={styles.fixedValue}>{String(value)}</span>{' '}
            <span>{String(item[key as keyof typeof item]!)}</span>
          </li>
        ))}
      </ul>
      <div className={styles.actions}>
        <Button theme="secondary" onClick={resolveFixes} className={styles.tiny}>
          Apply Changes
        </Button>
        <Button theme="warn" onClick={deleteItem} className={styles.tiny}>
          Delete
        </Button>
      </div>
    </div>
  )
}
