import { Token } from 'api/template'
import { FC, useCallback } from 'react'
import { ActionButton, Card, IconButton, Tooltip } from 'ui'
import { cn } from 'utils'
import { IconRedo, IconUndo } from './icons'
import styles from './toolbox-actions.module.scss'
import { useEditorApi, useEditorState } from '../state'
import { getRawTokens, hasFixedItems } from '../state/selectors'

interface Props {
  className?: string
  onSave: (tokens: Token.Raw[]) => Promise<void>
}
export const ToolboxActions: FC<Props> = ({ className, onSave, ...props }) => {
  const api = useEditorApi()
  const state = useEditorState()
  const { history, historyIndex } = state
  const hasFixes = hasFixedItems(state)

  const save = useCallback(async () => {
    try {
      await onSave(getRawTokens(state))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      throw e
    }
  }, [onSave, state])

  return (
    <Card {...props} className={cn(styles.card, className)}>
      <IconButton
        className={styles.button}
        onClick={api?.undo}
        disabled={historyIndex <= 0}
        data-tooltip-content="Undo"
        data-tooltip-id={Tooltip.ID}
      >
        <IconUndo />
      </IconButton>
      <IconButton
        className={styles.button}
        onClick={api?.redo}
        disabled={historyIndex >= history.length - 1}
        data-tooltip-content="Redo"
        data-tooltip-id={Tooltip.ID}
      >
        <IconRedo />
      </IconButton>
      <ActionButton
        theme="primary"
        disabled={hasFixes}
        onClick={save}
        className={styles.save}
        message="Template saved"
      >
        Save
      </ActionButton>
    </Card>
  )
}
