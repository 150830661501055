import { Token } from 'api/template'
import { IconCosigner, IconEmployer, IconGuarantor } from 'icons'
import { FC } from 'react'
import styles from './role-icon.module.scss'

interface Props {
  role: Token.Role
}

export const RoleIcon: FC<Props> = ({ role }) => {
  switch (role) {
    case 'Tenant1':
    case 'Tenant2':
    case 'Tenant3':
    case 'Tenant4':
    case 'Tenant5':
    case 'Tenant6':
      return <IconCosigner className={styles.icon} />
    case 'Guarantor1':
    case 'Guarantor2':
    case 'Guarantor3':
    case 'Guarantor4':
    case 'Guarantor5':
    case 'Guarantor6':
      return <IconGuarantor className={styles.icon} />
    case 'Owner':
      return <IconEmployer className={styles.icon} />
  }
  return null
}
